function infoTextHeightMin() {
	var parentTop = $('.info_text').position().top
	var childTop = $('.open_text').position().top
	if($(window).outerWidth() < 992)
	{
		childTop = $('.open_text_mob').position().top
	}
	$('.info_text').height(childTop-parentTop+5);
}

function infoTextHeight() {
	var childHeight = 0;
	$('.info_text p').each(function(i,e) {
		childHeight	+= $(e).outerHeight(true);
	});
	$('.info_text').height(childHeight + 17);
}
function categoryInfoBlockHeight() {
	var childHeight = 0;
	$('.category_info_block p').each(function(i,e) {
		childHeight	+= $(e).outerHeight(true);
	});
	$('.category_info_block').height(childHeight + 17);
}

function slideUpMenu() {
  showMenu = false;
  // var mobileMenuBlockHeight = $('.header_menu_list').height();
  $('.header_menu_list').slideUp('fast');

}